import { Injectable } from '@angular/core';
import { PageEvent } from "@angular/material/paginator";
import { TableConfigModel } from "@app/shared/components/table/model";
import { TableComponent } from "@app/shared/components/table";

@Injectable({
  providedIn: 'root'
})
export class PaginationListService {
  data: any[] = [];
  pageSize: number = 5;
  pageIndex: number = 0;

  setData(data: any[], pageSize: number = 5) {
    this.data = data;
    this.pageSize = pageSize;
  }

  setTableConfig(
    keys: string[],
    labels: string[],
    data: any[]  = [],
    pageSize: number = 5
  ): TableConfigModel {
    let table: TableConfigModel = new TableConfigModel();
    this.data = data;
    table.dataKey = keys;
    table.dataLabel = labels;
    table.dataTotal = data.length;
    table.dataSource = this.getCurrentPageData();
    table.pageSize = pageSize;
    // table.pageIndex = 1;
    return table;
  }

  getCurrentPageData(): any[] {
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.data ? this.data.slice(startIndex, endIndex) : [];
  }

  getTotalPages(): number {
    return Math.ceil(this.data.length / this.pageSize);
  }

  updatePageInfo(pageInfo:PageEvent): void {
    this.pageIndex = pageInfo.pageIndex;
    this.pageSize = pageInfo.pageSize;
  }

  updatePage(page: PageEvent, tableConfig: TableConfigModel): void {
    this.updatePageInfo(page);
    tableConfig.dataSource = this.getCurrentPageData();
  }

  getCurrentPageIndex(): number {
    return this.pageIndex;
  }

  getCurrentPageSize(): number {
    return this.pageSize;
  }
}
